ol.list-steps {
  $active: $secondary;
  $activatable: $secondary;
  $width: 2rem;
  $border-width: 0.5rem;
  $border-lighten: 15%;

  padding: 0;
  margin: 0;
  counter-reset: steps;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
  margin: 3rem 0;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: rgba($gray-500, 0.5);

    &.active {
      color: $secondary;
    }

    &::before {
      counter-increment: steps;
      content: '';
      display: inline-block;
      // background: $secondary;
      z-index: 1;
      background: lighten($gray-400, $border-lighten);
      color: color-yiq($gray-300);
      border-radius: 50%;
      border: $border-width solid lighten($gray-500, $border-lighten);
      width: $width;
      height: $width;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    &.activatable::before {
      background: $secondary;
      color: color-yiq($activatable);
      border: $border-width solid lighten($activatable, $border-lighten);
    }

    &.active::before {
      background: $active;
      color: color-yiq($active);
      border: $border-width solid lighten($active, $border-lighten);
      animation: pulsate 1s infinite ease-in-out alternate;
    }

    flex: 1 1 100%;
    &::after {
      position: absolute;
      z-index: 0;
      top: ($width - $border-width) / 2;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: $border-width;
      background: lighten($gray-500, $border-lighten);
    }
    &.active::after {
      background: lighten($active, $border-lighten);
    }
    &.activatable::after {
      background: lighten($gray-500, $border-lighten);
    }
    &.activatable.active::after {
      background: lighten($activatable, $border-lighten);
    }
    &:first-child::after {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child::after {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  @keyframes pulsate {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  }
}
